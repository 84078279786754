<template lang="pug">
	.widget-container
		el-row(:gutter="24")
			el-col(:xs="24")
				el-card.box-card
					.clearfix.d-flex.j-between(slot="header")
						h2.no-line-height.text-left Reports
						.extra
					el-row.mar.t-1(:gutter="24")
						el-col(:xs="24" :sm="8")
							el-input(v-model="paginate.keyword" size="mini" placeholder="Search" v-debounce:300ms="fetchData")
					el-divider
					el-row.mg.b0-5()
						el-col(:span="24")
							el-pagination.mar.a-1.text-left(background="" 	@size-change="fetchData" @current-change="fetchData" :current-page.sync="paginate.page" :page-sizes="[10, 20, 30, 40]" :page-size.sync="paginate.limit" layout="prev, pager, next, sizes " :total="count")
					el-table(id="data-list", v-loading='busy', :data='list', element-loading-text='Loading', border='', fit='', highlight-current-row='')
						el-table-column(align='center', label="id" width='175')
							template(slot-scope='scope')
								span {{ scope.row.id }}
</template>

<style lang="scss">

</style>

<script>
export default {

}
</script>
